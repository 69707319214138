import { Common } from '@thecvlb/design-system/lib/src';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';

import StepItem from '../StepItem';

const SuccessPaymentAndFailedReleaseHold: React.FC<{ updateError: string }> = ({ updateError }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="mb-4 flex justify-center gap-6">
        <StepItem iconName="card" text="Paid" color="green" />
        <StepItem iconName="prescription-outline" highlight text="On hold" color="yellow" />
      </div>
      <h3 className="mb-4 text-center text-xl font-bold text-gray-700">
        Payment updated successfully however could not release hold
      </h3>
      <p className="mb-6 text-center text-base font-medium text-gray-700">
        The order has been successfully updated with payment information.
      </p>
      <p className="mb-6 text-center text-base text-gray-700">
        <span className="font-bold">Error</span> - [{updateError}]
      </p>
      <Common.Button className="w-full justify-center" color="blue" onClick={() => dispatch(closeModal())} size="sm">
        Close
      </Common.Button>
    </>
  );
};

export default SuccessPaymentAndFailedReleaseHold;
