import { useAppSelector } from 'hooks/redux';

import OrderInfo from '../../Overview/OrderInfo';

const Body: React.FC = () => {
  const { prescriptionDetails } = useAppSelector(({ prescriptions }) => prescriptions);
  const orderDetails = prescriptionDetails;

  return (
    <>
      <div className="h-auto divide-y px-6">
        <div className="grid gap-x-16 gap-y-2 py-4">
          <OrderInfo order={orderDetails} headingClassNames="mb-2 font-bold text-gray-700" />
        </div>
      </div>
    </>
  );
};

export default Body;
