import { useCallback, useEffect, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import PrescribeCompound from 'components/modals/PrescribeCompound';
import { PRESCRIPTIONS_TABLE_ACTIONS_PERMISSION } from 'components/tables/PatientPrescriptions/patientPrescriptions.settings';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import queryString from 'query-string';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { openModal } from 'store/modal/modalSlice';
import { PatientProps } from 'store/patients/patients.types';
import { selectUser } from 'store/user/userSlice';
import { hasPermission } from 'utils/popupsContentPermission';

import { PRESCRIPTIONS_TABS } from './prescriptions.settings';
import { PrescriptionsTabProps } from './prescriptions.types';
import PrescriptionTable from './PrescriptionTable';

const selectUserState = createSelector(selectUser, (user) => ({
  userRoleInfo: user.userRoleInfo
}));

const Prescriptions: React.FC<{ patient: PatientProps }> = ({ patient }) => {
  const { userRoleInfo } = useAppSelector(selectUserState);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = queryString.parse(searchParams.toString());
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState(PRESCRIPTIONS_TABS[0]?.label);
  const isWeightManagement =
    patient.planInfo?.planName === 'Weight Management' ||
    patient?.membership === 'Weight Management';
  const hasFullPrescriptionsAccess = hasPermission(
    PRESCRIPTIONS_TABLE_ACTIONS_PERMISSION,
    userRoleInfo.shortName
  );

  const showPrescribeCompound = isWeightManagement && hasFullPrescriptionsAccess;

  const filteredPrescriptionTabs = PRESCRIPTIONS_TABS.filter((item) => {
    if (hasFullPrescriptionsAccess) {
      return item;
    } else {
      return !item.label.includes('Outside of LifeMD');
    }
  });

  const handleNewPrescription = () => {
    dispatch(
      openModal({
        hideClose: true,
        modalContent: <PrescribeCompound patientId={patient._id} patientInfo={patient} />,
        size: 'sm'
      })
    );
  };

  const handleChangeCurrentTab = useCallback(
    (tabData: PrescriptionsTabProps) => {
      queryParams.limit = '10';
      queryParams.pageNo = '0';
      if (tabData.value === 'all') {
        delete queryParams.isDraft;
        delete queryParams.isStableDose;
        delete queryParams.type;
      } else if (tabData.param === 'type') {
        delete queryParams.isDraft;
        delete queryParams.isStableDose;
      } else if (tabData.param === 'isDraft') {
        delete queryParams.type;
        delete queryParams.isStableDose;
      } else if (tabData.param === 'isStableDose') {
        delete queryParams.type;
        delete queryParams.isDraft;
      }
      if (tabData.param) {
        queryParams[tabData.param] = tabData.value;
      }
      setTab(tabData.label);
      setSearchParams(queryParams as URLSearchParamsInit);
    },
    [queryParams, setSearchParams]
  );

  useEffect(() => {
    if (queryParams) {
      if ('isDraft' in queryParams || 'type' in queryParams || 'isStableDose' in queryParams) {
        let currentParam = 'type';
        if ('isDraft' in queryParams) {
          currentParam = 'isDraft';
        } else if ('isStableDose' in queryParams) {
          currentParam = 'isStableDose';
        }

        const currentTab = PRESCRIPTIONS_TABS.find(
          (tabData) => tabData.param === currentParam && queryParams[currentParam] === tabData.value
        );
        currentTab && setTab(currentTab?.label);
      } else {
        setTab(PRESCRIPTIONS_TABS[0]?.label);
      }
    }
  }, [queryParams]);

  return (
    <>
      <div className="px-6 pb-8 pt-4">
        <div data-testid="header" className="mt-4 flex items-center justify-between">
          <span className="hidden text-xl font-bold text-gray-700 md:block">Prescriptions</span>
          {showPrescribeCompound && (
            <Common.Button
              preIcon="lifemd"
              color="white"
              style="pill"
              onClick={handleNewPrescription}
            >
              New prescription
            </Common.Button>
          )}
        </div>
        <div className="my-4">
          <Common.Tabs
            data={filteredPrescriptionTabs}
            defaultSelected={[tab]}
            type="bar"
            onChange={(tabData) => handleChangeCurrentTab(tabData as PrescriptionsTabProps)}
          />
        </div>
        <PrescriptionTable patientInfo={patient} />
      </div>
    </>
  );
};

export default Prescriptions;
