import { FieldValues } from 'react-hook-form';
import { PatientPrescription } from 'store/prescriptions/prescriptions.types';

export interface OrderInfoProps {
  order: PatientPrescription;
  headingClassNames?: string;
  wrapperClassNames?: string;
  labelClassNames?: string;
  isEditable?: boolean;
}

export interface OrderInfoItem {
  label: string;
  value: string | JSX.Element;
  property: string;
  isUnderline?: boolean;
}

export interface OrderFormStateProps extends FieldValues {
  trackingNumber: number;
  shippingStatus: { label: string; value: string };
  orderStatus: { label: string; value: string };
  pharmacyStatus: { label: string; value: string };
}

export type OrderFormValues = {
  trackingNumber: string;
  shippingStatus: { value: string; label: string };
  orderStatus: { value: string; label: string };
  pharmacyStatus: { value: string; label: string };
};

export enum OrderType {
  Local = 'Local',
  Wells = 'Wells',
  TT = 'Honeybee',
  TPS = 'TPS',
  Curexa = 'Curexa'
}

export enum OrderStatusLabels {
  Created = 'Created',
  Updated = 'Last Updated',
  MaxioLink = 'Invoice ID',
  Payment = 'Payment',
  Pharmacy = 'Pharmacy',
  Shipping = 'Shipping',
  Survey = 'Survey',
  Renewal = 'Renewal',
  OrderId = 'Order ID',
  CreatedDate = 'Created Date',
  TrackingNumber = 'Tracking Number',
  DeliveryAddress = 'Delivery Address'
}

export enum OrderStatusProperties {
  Payment = 'paymentStatus',
  Pharmacy = 'pharmacyStatus',
  Shipping = 'shippingStatus'
  // Survey = 'surveyStatus',
  // Renewal = 'renewalStatus',
}

export enum OrderStatusIcons {
  Payment = 'card',
  Pharmacy = 'prescription-filled',
  Shipping = 'truck',
  Survey = 'list',
  Renewal = 'card'
}

export enum PaymentStatus {
  Paid = 'paid',
  Failed = 'failed',
  WaitingForPayment = 'waiting for payment',
  Blocked = 'blocked',
  Na = 'na'
}

export type PaymentStatusColors = {
  [key in PaymentStatus]: string;
};

export const paymentStatusColors = {
  [PaymentStatus.Paid]: 'text-green-500',
  [PaymentStatus.Failed]: 'text-red-500',
  [PaymentStatus.WaitingForPayment]: 'text-yellow-500',
  [PaymentStatus.Blocked]: 'text-gray-500',
  [PaymentStatus.Na]: 'text-gray-500'
};

export enum PharmacyStatus {
  CompleteCovered = 'complete - covered',
  CompleteNotCovered = 'complete - not covered',
  InsuranceNotProvided = 'insurance not provided',
  Processed = 'processed',
  OnHold = 'on hold',
  PaReuested = 'pa requested',
  InProgress = 'in progress',
  ReleasedFromHold = 'released from hold',
  Na = 'na'
}

export type PharmacyStatusColors = {
  [key in PharmacyStatus]: string;
};

export const pharmacyStatusColors: PharmacyStatusColors = {
  [PharmacyStatus.CompleteCovered]: 'text-green-500',
  [PharmacyStatus.CompleteNotCovered]: 'text-green-500',
  [PharmacyStatus.InsuranceNotProvided]: 'text-green-500',
  [PharmacyStatus.Processed]: 'text-yellow-500',
  [PharmacyStatus.OnHold]: 'text-yellow-500',
  [PharmacyStatus.PaReuested]: 'text-yellow-500',
  [PharmacyStatus.InProgress]: 'text-yellow-500',
  [PharmacyStatus.ReleasedFromHold]: 'text-yellow-500',
  [PharmacyStatus.Na]: 'text-gray-500'
};

export enum ShippingStatus {
  Delivered = 'delivered',
  Shipped = 'shipped',
  Warnings = 'warnings',
  Incomplete = 'incomplete',
  Shipping = 'shipping',
  Blocked = 'blocked',
  InProgress = 'in progress',
  Na = 'na'
}

export type ShippingStatusColors = {
  [key in ShippingStatus]: string;
};

export const shippingStatusColors: ShippingStatusColors = {
  [ShippingStatus.Delivered]: 'text-green-500',
  [ShippingStatus.Shipped]: 'text-green-500',
  [ShippingStatus.Warnings]: 'text-red-500',
  [ShippingStatus.Incomplete]: 'text-red-500',
  [ShippingStatus.Shipping]: 'text-yellow-500',
  [ShippingStatus.Blocked]: 'text-gray-500',
  [ShippingStatus.InProgress]: 'text-green-500',
  [ShippingStatus.Na]: 'text-gray-500'
};
