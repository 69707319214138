import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';
import { useLazyGetBillingHistoryQuery } from 'store/patients/patientsSlice';
import { selectPrescriptionPane } from 'store/prescriptionPane/prescriptionPaneSlice';

import { MissingPaymentReasons, NOT_LISTED_VALUE } from '../releaseOrderFromHold.types';
import StepItem from '../StepItem';

const InformationMissing: React.FC<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  const { patientId } = useAppSelector(selectPrescriptionPane);
  const [getBillingHistory, { data: billingData, isFetching }] = useLazyGetBillingHistoryQuery();
  const dispatch = useAppDispatch();
  const [invoiceList, setInvoiceList] = useState([
    { label: 'Not listed', value: NOT_LISTED_VALUE }
  ]);
  const { control, watch, formState } = useFormContext();
  const watchListedInvoice = watch('listedInvoice');

  useEffect(() => {
    getBillingHistory({ id: patientId, params: {} });
  }, [getBillingHistory]);

  useEffect(() => {
    const billingDataInvoices = billingData?.data;

    const truncateInvoiceTitle = (input: string) => {
      if (input.length > 20) {
        input = input.substring(0, 19) + '...';
      }
      return input;
    };

    const invoicesFormatted = billingDataInvoices?.map((item) => {
      return {
        label: `${dayjs(item.createdAt).format('MM/DD/YY')} • $${parseFloat(Number(item.amount).toFixed(2))} • ${truncateInvoiceTitle(item.title)} • ${item.uid}`,
        value: item.uid
      };
    });

    if (invoicesFormatted) {
      setInvoiceList([...invoiceList, ...invoicesFormatted]);
    }
  }, [billingData]);

  const reasonsOptions = (
    Object.keys(MissingPaymentReasons) as Array<keyof typeof MissingPaymentReasons>
  ).map((key) => {
    return { label: MissingPaymentReasons[key], value: MissingPaymentReasons[key] };
  });

  return (
    <>
      <div className="mb-4 flex justify-center gap-6">
        <StepItem iconName="card" text="Unpaid" color="red" />
      </div>
      <h3 className="mb-2 text-center text-xl font-bold text-gray-700">
        Payment information is missing
      </h3>
      <p className="mb-4 text-base font-medium text-gray-700">
        To release the hold from this order, it must have a ‘Paid’ status. If there is a known
        invoice for the order, you can fix this issue by connecting the invoice to the order below.
      </p>
      <div className="mb-4">
        <Controller
          name="listedInvoice"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Common.SelectAlt
              className="mb-4"
              loading={isFetching}
              name={field.name}
              dataTestId=""
              size="sm"
              label="Order invoice"
              labelPosition="top"
              hint='Options above only include known "paid" invoices from Maxio'
              value={field.value}
              placeholder="Select..."
              hideSuccessState
              error={formState.errors[field.name] as FieldError}
              options={invoiceList}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </div>
      {watchListedInvoice === NOT_LISTED_VALUE && (
        <div className="mb-4">
          <Controller
            name="manualEntryInvoice"
            control={control}
            rules={{ required: watchListedInvoice === NOT_LISTED_VALUE }}
            render={({ field }) => (
              <Common.Input
                className="mb-4"
                name={field.name}
                dataTestId=""
                size="sm"
                label="Invoice ID"
                value={field.value}
                errors={formState.errors[field.name] as FieldError}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
      )}
      <div className="mb-8">
        <Controller
          name="reasons"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Common.MultiSelect
              className="mb-4"
              dataTestId=""
              size="sm"
              label="Reasons payment was missing"
              // labelPosition="top"
              helperText="Adding reasons here helps to provide us context on what occured"
              placeholder="Select reasons..."
              value={field.value}
              errors={formState.errors[field.name] as FieldError}
              options={reasonsOptions}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </div>
      <div className="flex justify-end gap-2">
        <Common.Button
          type="button"
          color="white-alt"
          onClick={() => dispatch(closeModal())}
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button
          type="submit"
          color="blue"
          size="sm"
          isLoading={isLoading}
          disabled={!formState.isValid}
        >
          Confirm
        </Common.Button>
      </div>
    </>
  );
};

export default InformationMissing;
