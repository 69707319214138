import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import EditTitrations from 'components/modals/EditTitrations';
import { PRESCRIPTIONS_TABLE_ACTIONS_PERMISSION } from 'components/tables/PatientPrescriptions/patientPrescriptions.settings';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { getDisplayName } from 'store/patients/patients.settings';
import { selectUser } from 'store/user/userSlice';
import { hasPermission } from 'utils/popupsContentPermission';

import { TitrationCellProps } from './titrationCell.types';

const selectUserState = createSelector(selectUser, (user) => ({
  userRoleInfo: user.userRoleInfo
}));

const TitrationCell: React.FC<TitrationCellProps> = ({
  titration,
  titrations,
  prescriptionId,
  patientInfo
}) => {
  const { userRoleInfo } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const patientName = getDisplayName(patientInfo);
  const titrationText = titration?.qty
    ? `${titration?.qty} ${titration?.units?.toLowerCase()}`
    : '--';

  const handleEditTitrations = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: (
          <EditTitrations
            titrations={titrations}
            prescriptionId={prescriptionId}
            patientName={patientName}
          />
        )
      })
    );
  };

  const showEditTitrationsButton = hasPermission(
    PRESCRIPTIONS_TABLE_ACTIONS_PERMISSION,
    userRoleInfo.shortName
  );

  return (
    <span className="flex max-w-[300px] items-center justify-between gap-2.5 font-medium">
      <span className="whitespace-nowrap">{titrationText}</span>
      {showEditTitrationsButton && (
        <Common.Button
          data-testid="edit_titrations"
          preIcon="pencil"
          size="sm"
          color="white"
          onClick={handleEditTitrations}
        />
      )}
    </span>
  );
};

export default TitrationCell;
