import { Dispatch, SetStateAction } from 'react';

import InformationMissing from './InformationMissing';
import Loading from './Loading';
import { ReleaseFromOnHoldSteps } from './releaseOrderFromHold.types';
import SuccessPaymentAndFailedReleaseHold from './SuccessPaymentAndFailedReleaseHold';
import SuccessPaymentAndReleasedHold from './SuccessPaymentAndReleasedHold';
import UpdateFailed from './UpdateFailed';

export const renderContent = (
  step: ReleaseFromOnHoldSteps | undefined,
  setStep: Dispatch<SetStateAction<ReleaseFromOnHoldSteps | undefined>>,
  isLinkInvoiceLoading: boolean,
  isReleaseOrderFromHoldLoading: boolean,
  updateError: string,
) => {
  switch (step) {
    case ReleaseFromOnHoldSteps.InformationMissing:
      return <InformationMissing isLoading={isLinkInvoiceLoading || isReleaseOrderFromHoldLoading} />;
    case ReleaseFromOnHoldSteps.Loading:
      return <Loading />;
    case ReleaseFromOnHoldSteps.SuccessPaymentAndReleasedHold:
      return <SuccessPaymentAndReleasedHold />;
    case ReleaseFromOnHoldSteps.SuccessPaymentAndFailedReleaseHold:
      return <SuccessPaymentAndFailedReleaseHold updateError={updateError} />;
    case ReleaseFromOnHoldSteps.UpdateFailed:
      return <UpdateFailed setStep={setStep} updateError={updateError} />;
    default:
      return;
  }
};
