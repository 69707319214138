import { Common } from '@thecvlb/design-system/lib/src';
import { useAppDispatch } from 'hooks/redux';
import { changeModalSize, closeModal } from 'store/modal/modalSlice';

import { ReleaseFromOnHoldSteps, UpdateFailedProps } from '../releaseOrderFromHold.types';
import StepItem from '../StepItem';

const UpdateFailed: React.FC<UpdateFailedProps> = ({ setStep, updateError }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="mb-4 flex justify-center gap-6">
        <StepItem iconName="card" text="Unpaid" color="red" highlight />
      </div>
      <h3 className="mb-4 text-center text-xl font-bold text-gray-700">Update failed</h3>
      <p className="mb-6 text-center text-base font-medium text-gray-700">
        Invoice provided could not be added to the order.
      </p>
      <p className="mb-6 text-center text-base text-gray-700">
        <span className="font-bold">Error</span> - [{updateError}]
      </p>
      <div className="flex gap-2">
        <Common.Button
          className="w-full justify-center"
          type="button"
          color="white-alt"
          onClick={() => dispatch(closeModal())}
          size="sm"
        >
          Close
        </Common.Button>
        <Common.Button
          className="w-full justify-center"
          type="button"
          color="blue"
          onClick={() => {
            dispatch(
              changeModalSize({
                size: 'lg',
              }),
            );
            setStep(ReleaseFromOnHoldSteps.InformationMissing);
          }}
          size="sm"
        >
          Go Back
        </Common.Button>
      </div>
    </>
  );
};

export default UpdateFailed;
