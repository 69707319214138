import { useEffect, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { ColumnDef, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/PatientPrescriptions/column';
import { PRESCRIPTIONS_TABLE_ACTIONS_PERMISSION } from 'components/tables/PatientPrescriptions/patientPrescriptions.settings';
import { PatientPrescriptionsType } from 'enums/prescriptions';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { open } from 'store/prescriptionPane/prescriptionPaneSlice';
import { useGetPatientPrescriptionsQuery } from 'store/prescriptions/prescriptionsSlice';
import { selectUser } from 'store/user/userSlice';
import { hasPermission } from 'utils/popupsContentPermission';

import { PrescriptionTableProps } from './prescriptionTable.types';
import { PatientPrescription } from '../../../../store/prescriptions/prescriptions.types';
import PrescriptionPane from '../../../prescription/prescriptionPane/PrescriptionPane';

const selectUserState = createSelector(selectUser, (user) => ({
  userRoleInfo: user.userRoleInfo
}));

const PrescriptionTable: React.FC<PrescriptionTableProps> = ({ patientInfo }) => {
  const { userRoleInfo } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const patientId = patientInfo?._id || '';
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    const queryParamsData = queryString.parse(searchParams.toString());
    if (queryParamsData?.taskModalOpenID) delete queryParamsData?.taskModalOpenID;
    if (queryParamsData?.prescriptionModalOpenID) delete queryParamsData?.prescriptionModalOpenID;
    return queryParamsData.limit ? queryParamsData : { limit: 10, ...queryParamsData };
  }, [searchParams]);

  const { data, isLoading, isFetching } = useGetPatientPrescriptionsQuery({
    patientId,
    params: queryParams
  });
  const isFetchingData = isLoading || isFetching;

  const hasFullPrescriptionsAccess = hasPermission(
    PRESCRIPTIONS_TABLE_ACTIONS_PERMISSION,
    userRoleInfo.shortName
  );
  const filteredPrescriptions = useMemo(() => {
    return data?.data.filter((prescription) => {
      if (hasFullPrescriptionsAccess) {
        return prescription;
      } else {
        return prescription.type !== PatientPrescriptionsType.Prescription;
      }
    });
  }, [data]);

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetchingData,
    columns: columns(patientInfo),
    data: filteredPrescriptions || []
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  const handleOpenPrescription = (rowData: RowData) => {
    const { id, type } = rowData as PatientPrescription;
    if (type !== PatientPrescriptionsType.Order) return;
    const newParams = new URLSearchParams(searchParams);
    newParams.set('prescriptionModalOpenID', id);
    setSearchParams(newParams, { replace: true });
  };

  useEffect(() => {
    const prescriptionId = searchParams.get('prescriptionModalOpenID');

    if (prescriptionId && patientId) {
      dispatch(open({ prescriptionId, patientId }));
    }
  }, [queryParams, dispatch, searchParams]);

  return (
    <div>
      <BasicTable
        table={table}
        isFetching={isLoading}
        totalCount={data?.info.totalCount || 0}
        onClickRow={handleOpenPrescription}
      />
      <PrescriptionPane />
    </div>
  );
};

export default PrescriptionTable;
