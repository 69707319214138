import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { PrescriptionPaneProps } from './prescriptionPane.types';

const initialState: PrescriptionPaneProps = {
  prescriptionId: '',
  patientId: '',
  staffMemberOrderTeams: undefined,
  isOpen: false,
  isLoading: false
};

const prescriptionPaneSlice = createSlice({
  name: 'prescriptionPane',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      if (action.payload.prescriptionId) state.prescriptionId = action.payload.prescriptionId;
      if (action.payload.patientId) state.patientId = action.payload.patientId;
    },
    setStaffMemberOrderTeams: (state, action) => {
      if (action.payload.staffMemberOrderTeams) {
        state.staffMemberOrderTeams = action.payload.staffMemberOrderTeams;
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    reset: () => initialState
  }
});

export const selectPrescriptionPane = (state: RootState) => state.prescriptionPane;

export const { open, reset, setIsLoading, setStaffMemberOrderTeams } =
  prescriptionPaneSlice.actions;

export default prescriptionPaneSlice.reducer;
