import { Common } from '@thecvlb/design-system/lib/src';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';

import StepItem from '../StepItem';

const SuccessPaymentAndReleasedHold: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="mb-4 flex justify-center gap-6">
        <StepItem iconName="card" color="green" text="Paid" />
        <StepItem iconName="prescription-filled" color="yellow" text="Released hold" />
      </div>
      <h3 className="mb-4 text-center text-xl font-bold text-gray-700">Successfully added payment & released hold</h3>
      <p className="mb-6 text-center text-base font-medium text-gray-700">
        The order has been released from on hold and so next steps are waiting for the pharmacy to process and fulfill
        the order.
      </p>
      <Common.Button
        className="w-full justify-center"
        type="button"
        color="blue"
        onClick={() => dispatch(closeModal())}
        size="sm"
      >
        Close
      </Common.Button>
    </>
  );
};

export default SuccessPaymentAndReleasedHold;
