import { Dispatch, SetStateAction } from 'react';

import { PatientPrescription } from 'store/prescriptions/prescriptions.types';

export interface ReleaseOrderFromHoldProps {
  order: PatientPrescription;
}

export interface ReleaseOrderFromHoldFormData {
  listedInvoice: string | null;
  manualEntryInvoice: string | null;
  reasons: { label: MissingPaymentReasons; value: MissingPaymentReasons }[] | undefined;
}

export enum ReleaseFromOnHoldSteps {
  InformationMissing = 'Information missing',
  Loading = 'Loading',
  SuccessPaymentAndReleasedHold = 'Successfully added payment & released hold',
  SuccessPaymentAndFailedReleaseHold = 'Successfully added payment & failed release hold',
  UpdateFailed = 'Update failed'
}

export interface UpdateFailedProps {
  setStep: Dispatch<SetStateAction<ReleaseFromOnHoldSteps | undefined>>;
  updateError: string;
}

export const NOT_LISTED_VALUE = 'Not listed';

export enum MissingPaymentReasons {
  InvoiceNotCreated = 'Invoice not created at the time of the order',
  MedicationIncluded = 'Medication included in plan',
  PaymentDidNotFail = 'Payment did not fail',
  PaymentWasCollectedAfter = 'Payment was collected after the invoice was issued',
  ReplacementOrder = 'Replacement order',
  TestOrder = 'Test order',
  VipPatient = 'VIP patient',
  NotListed = 'Not listed'
}
