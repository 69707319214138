import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { Address, OrdersTypes } from 'store/orders/orders.types';
import { PatientPrescription } from 'store/prescriptions/prescriptions.types';
import { ShortTeamData } from 'store/staffs/staffs.types';

import {
  OrderFormValues,
  OrderInfoItem,
  OrderStatusIcons,
  OrderStatusLabels,
  OrderStatusProperties,
  OrderType,
  paymentStatusColors,
  PaymentStatusColors,
  pharmacyStatusColors,
  PharmacyStatusColors,
  shippingStatusColors,
  ShippingStatusColors
} from './orderInfo.types';

const statesAbbreviations: { [x: string]: string } = {
  al: 'Alabama',
  ak: 'Alaska',
  as: 'American_Samoa',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  de: 'Delaware',
  dc: 'District of Columbia',
  fm: 'Federated States Of Micronesia',
  fl: 'Florida',
  ga: 'Georgia',
  gu: 'Guam',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  mh: 'Marshall_Islands',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  mp: 'Northern Mariana Islands',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pw: 'Palau',
  pa: 'Pennsylvania',
  pr: 'Puerto Rico',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  vi: 'Virgin Islands',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming'
};

const getStateAbbreviation = (stateName: string) => {
  const matchingAbbreviations = Object.keys(statesAbbreviations).filter(
    (abbreviation) => statesAbbreviations[abbreviation].toLowerCase() === stateName.toLowerCase()
  );
  return matchingAbbreviations.length > 0 ? matchingAbbreviations[0].toUpperCase() : stateName;
};

export const getAddress = (address: Address) => {
  const results = [];
  if (address.line1) {
    results.push(address.line1);
  }
  if (address.line2) {
    results.push(address.line2);
  }
  if (address.city) {
    results.push(address.city);
  }
  if (address.state) {
    results.push(getStateAbbreviation(address.state));
  }

  const joinedAddress = results.join(', ');

  if (address.postal) return `${joinedAddress} ${address.postal}`;
  return joinedAddress;
};

const getOrderStatusTags = (order: PatientPrescription) => {
  return [
    {
      label: OrderStatusLabels.Payment,
      property: OrderStatusProperties.Payment,
      icon: OrderStatusIcons.Payment,
      color:
        paymentStatusColors[
          (order.paymentStatus?.toLowerCase() as keyof PaymentStatusColors) || 'na'
        ]
    },
    {
      label: OrderStatusLabels.Pharmacy,
      property: OrderStatusProperties.Pharmacy,
      icon: OrderStatusIcons.Pharmacy,
      color:
        pharmacyStatusColors[
          (order.pharmacyStatus?.toLowerCase() as keyof PharmacyStatusColors) || 'na'
        ]
    },
    {
      label: OrderStatusLabels.Shipping,
      property: OrderStatusProperties.Shipping,
      icon: OrderStatusIcons.Shipping,
      color:
        shippingStatusColors[
          (order.shippingStatus?.toLowerCase() as keyof ShippingStatusColors) || 'na'
        ]
    }
  ];
};

const getItems = (order: PatientPrescription): OrderInfoItem[] => {
  return [
    {
      label: OrderStatusLabels.Created,
      property: 'createdAt',
      value: order.createdAt && (
        <span>{dayjs(order.createdAt).format(DateFormat.MM_DD_YYYY_HH_mmA)}</span>
      )
    },
    {
      label: OrderStatusLabels.MaxioLink,
      property: 'invoiceId',
      value: order.invoiceId ? <span>{order.invoiceId}</span> : <></>
    },
    {
      label: OrderStatusLabels.Pharmacy,
      property: 'pharmacy',
      value: order.pharmacy ? (
        <span>{OrderType[order.pharmacy as keyof typeof OrderType]}</span>
      ) : (
        <></>
      )
    },
    {
      label: OrderStatusLabels.TrackingNumber,
      property: 'trackingNumber',
      value: <span>{order.trackingNumber || '-'}</span>
    },
    {
      label: OrderStatusLabels.DeliveryAddress,
      property: 'shippingAddress',
      value: order.shippingAddress ? (
        <span>{getAddress(order.shippingAddress)}</span>
      ) : (
        'Unavailable'
      )
    },
    {
      label: OrderStatusLabels.Updated,
      property: 'orderUpdatedAt',
      value: order.orderUpdatedAt ? (
        <span>{dayjs(order.orderUpdatedAt).format(DateFormat.MM_DD_YYYY_HH_mmA)}</span>
      ) : (
        <></>
      )
    }
  ];
};

const getSubmitOrderData = (formData: OrderFormValues) => {
  return {
    trackingNumber: formData.trackingNumber,
    shippingStatus: formData.shippingStatus?.value || null,
    orderStatus: formData.orderStatus?.value || null,
    pharmacyStatus: formData.pharmacyStatus?.value || null
  };
};

const getShowReleaseFromHold = (
  order: PatientPrescription,
  staffMemberOrderTeams?: ShortTeamData[]
) => {
  const isCurexa = order.pharmacy === OrdersTypes.curexa;
  const isOnHold = order.pharmacyStatus === 'On hold';
  const isPharmacyTeamUser = staffMemberOrderTeams?.find(
    (item) => item.teamId === import.meta.env.VITE_PHARMACY_ORDER_TEAM
  );

  return isCurexa && isOnHold && !!isPharmacyTeamUser;
};

const sortOptions = (
  a: {
    label: string;
    value: string;
  },
  b: {
    label: string;
    value: string;
  }
) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

const sharedStatusOptions = [
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Error', value: 'Error' },
  { label: 'Closed', value: 'Closed' }
];

const orderOptions = [
  ...sharedStatusOptions,
  ...[
    { label: 'Created', value: 'Created' },
    { label: 'Completed', value: 'Completed' },
    { label: 'In progress', value: 'In progress' }
  ]
];

const pharmacyOptions = [
  ...sharedStatusOptions,
  ...[
    { label: 'Missing RX', value: 'Missing RX' },
    { label: 'Expired', value: 'Expired' },
    { label: 'Transferred', value: 'Transferred' },
    { label: 'Exception', value: 'Exception' },
    { label: 'In progress', value: 'In progress' },
    { label: 'Replacement', value: 'Replacement' },
    { label: 'Blocked', value: 'Blocked' },
    { label: 'PA required', value: 'PA required' },
    { label: 'Patient no response', value: 'Patient no response' },
    { label: 'Provider no response', value: 'Provider no response' }
  ]
];

const shippingOptions = [
  ...sharedStatusOptions,
  ...[
    { label: 'Local pharmacy', value: 'Local pharmacy' },
    { label: 'Shipping', value: 'Shipping' },
    { label: 'Delivered', value: 'Delivered' },
    { label: 'Exception', value: 'Exception' },
    { label: 'Blocked', value: 'Blocked' }
  ]
];

export {
  getOrderStatusTags,
  getItems,
  getSubmitOrderData,
  sortOptions,
  getShowReleaseFromHold,
  orderOptions,
  pharmacyOptions,
  shippingOptions
};
