import { Common } from '@thecvlb/design-system/lib/src';
import { IconProps } from '@thecvlb/design-system/lib/src/common/Icon';
import classNames from 'classnames';

const StepItem: React.FC<{
  highlight?: boolean;
  color: 'red' | 'green' | 'yellow';
  text: string;
  iconName: IconProps['name'];
}> = ({ highlight, color, text, iconName }) => {
  const highlightedWrapperClassName = classNames('rounded-2 rounded border-2 border-red-200 bg-red-50 p-2');

  const itemIconWrapperClassName = classNames('flex size-12 items-center justify-center self-center rounded-full', {
    'bg-green-100': color === 'green',
    'bg-red-100': color === 'red',
    'bg-yellow-100': color === 'yellow',
  });

  const iconClassName = classNames('size-6', {
    'text-green': color === 'green',
    'text-red': color === 'red',
    'text-yellow': color === 'yellow',
  });
  const item = () => (
    <div className="align-center flex flex-row justify-center gap-2">
      <span className={itemIconWrapperClassName}>
        <Common.Icon name={iconName} className={iconClassName} />
      </span>
      <span className="self-center">
        <Common.ColorTag color={color} text={text} />
      </span>
    </div>
  );

  const itemHighlighted = (item: JSX.Element) => <div className={highlightedWrapperClassName}>{item}</div>;

  return highlight ? itemHighlighted(item()) : item();
};

export default StepItem;
