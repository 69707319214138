import { createSlice } from '@reduxjs/toolkit';
import { PatientPrescriptionsType, PaymentStatus } from 'enums/prescriptions';
import { apiSlice } from 'store/api/apiSlice';
import { SubmitPrescriptionResponse } from 'store/weightManagement/weightManagement.types';

import {
  LinkInvoiceQueryParams,
  PatientPrescription,
  PatientPrescriptionsProps,
  ReleaseOrderFromHoldAltQueryParams,
  Titration
} from './prescriptions.types';

const initialState: PatientPrescription = {
  id: '',
  sig: '',
  name: '',
  type: PatientPrescriptionsType.Prescription,
  qty: 0,
  qtyUnits: 'ml',
  createdAt: '',
  signedAt: '',
  orderStatus: '',
  paymentStatus: PaymentStatus.Failed,
  titrations: [],
  renews: '',
  renewal: 0,
  renewalTaskId: '',
  fulfillmentState: '',
  deletedAt: ''
};

const prescriptionSlice = createSlice({
  name: 'prescription',
  initialState: {
    prescriptionDetails: initialState
  },
  reducers: {
    setPrescriptionDetails: (state, action) => {
      state.prescriptionDetails = action.payload;
    },

    resetPrescriptionDetails: (state) => {
      state.prescriptionDetails = initialState;
    }
  }
});

export const { setPrescriptionDetails, resetPrescriptionDetails } = prescriptionSlice.actions;

export default prescriptionSlice.reducer;

export const prescriptionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPatientPrescriptions: build.query<
      { data: PatientPrescription[]; info: { totalCount: number } },
      { patientId: string; params?: PatientPrescriptionsProps }
    >({
      query: ({ patientId, params }) => ({ url: `/patients/${patientId}/prescriptions`, params }),
      providesTags: (result) =>
        result
          ? [
              'Prescriptions',
              ...result.data?.map(({ id }) => ({ type: 'Prescriptions' as const, id }))
            ]
          : ['Prescriptions']
    }),
    getPrescriptionById: build.query<{ data: PatientPrescription }, { prescriptionId: string }>({
      query: ({ prescriptionId }) => ({ url: `/prescriptions/${prescriptionId}` }),
      providesTags: (_result, _error, arg) => [{ type: 'Prescriptions', id: arg.prescriptionId }]
    }),
    updatePrescription: build.mutation<
      { message: string },
      { prescriptionId: string; titrations: Partial<Titration>[] }
    >({
      query: ({ prescriptionId, titrations }) => ({
        url: `/prescriptions/${prescriptionId}`,
        method: 'PATCH',
        body: { titrations }
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Prescriptions', id: arg.prescriptionId }]
    }),
    retryPayment: build.mutation<{ data: SubmitPrescriptionResponse }, { prescriptionId: string }>({
      query: ({ prescriptionId }) => ({
        url: `/prescriptions/${prescriptionId}/payments`,
        method: 'POST'
      }),
      invalidatesTags: ['Prescriptions']
    }),
    releaseOrderFromHoldAlt: build.mutation<
      { message: string },
      ReleaseOrderFromHoldAltQueryParams
    >({
      query: ({ orderId }) => ({
        url: `${import.meta.env.VITE_API_URL}/planex/v2/orders/${orderId}/release`,
        method: 'POST'
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['Prescriptions']
    }),
    linkInvoiceToOrder: build.mutation<{ message: string }, LinkInvoiceQueryParams>({
      query: ({ orderId, invoiceId, reasons }) => ({
        url: `${import.meta.env.VITE_API_URL}/orders/${orderId}/elationprescriptions`,
        method: 'POST',
        body: { invoiceId, reasons }
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['Prescriptions']
    })
  })
});

export const {
  useGetPatientPrescriptionsQuery,
  useLazyGetPatientPrescriptionsQuery,
  useLazyGetPrescriptionByIdQuery,
  useGetPrescriptionByIdQuery,
  useUpdatePrescriptionMutation,
  useRetryPaymentMutation,
  useLinkInvoiceToOrderMutation,
  useReleaseOrderFromHoldAltMutation
} = prescriptionsApiSlice;
