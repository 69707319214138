import { useCallback, useEffect, useState } from 'react';

import Loader from 'components/common/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Drawer from 'react-modern-drawer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { reset, setStaffMemberOrderTeams } from 'store/prescriptionPane/prescriptionPaneSlice';
import {
  resetPrescriptionDetails,
  setPrescriptionDetails,
  useLazyGetPrescriptionByIdQuery
} from 'store/prescriptions/prescriptionsSlice';
import { useLazyGetStaffQuery } from 'store/staffs/staffsSlice';
import { selectUser } from 'store/user/userSlice';

import Body from './Body';
import Header from './Header';

import 'react-modern-drawer/dist/index.css';

export const PrescriptionPane = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    isOpen,
    prescriptionId,
    isLoading: isLoadingInSlidingPane
  } = useAppSelector((state) => state.prescriptionPane);
  const [
    getPrescriptionById,
    {
      data: prescriptionDetails,
      isFetching: prescriptionIsFetching,
      isLoading: prescriptionIsLoading
    }
  ] = useLazyGetPrescriptionByIdQuery();
  const [getStaff] = useLazyGetStaffQuery();
  const { _id: userId } = useAppSelector(selectUser);

  const [isLoadingClose, setIsLoadingClose] = useState(false);

  const showPrescriptionContent =
    prescriptionDetails && !prescriptionIsLoading && !prescriptionIsFetching;

  const handleClose = useCallback(() => {
    if (searchParams.has('prescriptionModalOpenID')) {
      searchParams.delete('prescriptionModalOpenID');
      setSearchParams(searchParams);
    }
    dispatch(resetPrescriptionDetails());
    dispatch(reset());
  }, [dispatch, searchParams, setSearchParams]);

  const onRequestClose = useCallback(() => {
    if (!isLoadingInSlidingPane) {
      handleClose();
    } else {
      setIsLoadingClose(true);
    }
  }, [handleClose, isLoadingInSlidingPane]);

  useEffect(() => {
    if (!searchParams.has('prescriptionModalOpenID')) {
      dispatch(reset());
      dispatch(resetPrescriptionDetails());
    }
  }, [location, searchParams, dispatch]);

  useEffect(() => {
    if (isOpen && prescriptionId) {
      getPrescriptionById({ prescriptionId })
        .unwrap()
        .then(({ data }) => {
          dispatch(setPrescriptionDetails(data));
        })
        .catch(() => dispatch(reset()));
    }
    // should not respond to changing search parameters
  }, [prescriptionId, dispatch, isOpen, prescriptionDetails]);

  useEffect(() => {
    if (isLoadingClose && !isLoadingInSlidingPane) {
      setIsLoadingClose(false);
    }

    return () => {
      dispatch(resetPrescriptionDetails());
      dispatch(reset());
    };
  }, [dispatch, isLoadingClose, isLoadingInSlidingPane]);

  useEffect(() => {
    getStaff({ staffId: userId })
      .unwrap()
      .then((response) => {
        dispatch(setStaffMemberOrderTeams({ staffMemberOrderTeams: response?.teams }));
      });
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={onRequestClose}
      direction="right"
      enableOverlay={false}
      className="max-w-[75%] rounded-l-2xl !bg-transparent md:max-w-[760px]"
      size="80%"
      style={{ top: 244, height: 'initial' }}
    >
      <div className="relative flex max-h-[calc(100vh-244px)] min-h-[500px] w-full flex-col gap-[20px] rounded-l-2xl bg-white">
        {showPrescriptionContent ? (
          <>
            <Header handleClose={handleClose} />
            <div className="overflow-y-auto">
              <Body />
              <Loader isVisible={prescriptionIsFetching || isLoadingClose} />
            </div>
          </>
        ) : (
          <Loader isVisible />
        )}
      </div>
    </Drawer>
  );
};

export default PrescriptionPane;
