import { Fragment } from 'react';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import ReleaseOrderFromHoldAlt from 'components/modals/ReleaseOrderFromHoldAlt';
import { getShowReleaseFromHold } from 'components/prescription/Overview/OrderInfo/orderInfo.settings';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';

const Header: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const dispatch = useAppDispatch();
  const { prescriptionDetails } = useAppSelector(({ prescriptions }) => prescriptions);
  const { staffMemberOrderTeams } = useAppSelector(({ prescriptionPane }) => prescriptionPane);
  const order = prescriptionDetails;
  const showReleaseFromHoldButton = getShowReleaseFromHold(order, staffMemberOrderTeams);

  const getMenuItemClasses = (active: boolean) =>
    classNames('group flex items-center font-semibold text-sm gap-3 py-4 px-6', {
      'bg-gray-100 text-gray900 cursor-pointer': active,
      'text-gray700': !active
    });

  const handleReleaseFromHold = () => {
    const notPaid = order.paymentStatus !== 'paid';
    if (notPaid) {
      dispatch(
        openModal({
          size: 'lg',
          hideClose: true,
          modalContent: <ReleaseOrderFromHoldAlt order={order} />
        })
      );
    } else {
      dispatch(
        openModal({
          size: 'sm',
          hideClose: true,
          modalContent: <ReleaseOrderFromHoldAlt order={order} />
        })
      );
    }
  };

  return (
    <>
      <div className="rounded-tl-2xl border-b-2 bg-gray-50 p-6">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-full items-center">
            <button
              data-testid="close_btn"
              onClick={handleClose}
              className="mr-4 size-8 rounded-full border border-gray-200 bg-gray-50 p-[5px] text-gray outline-none"
            >
              <Common.Icon data-testid="close_btn" name="close" />
            </button>
            {!!prescriptionDetails && (
              <>
                <div className="flex grow">
                  <div className="mr-4 flex flex-col self-center">
                    <Common.Logo name="cross" className="-mb-1 ml-auto size-3" />
                    <Common.Icon className="mr-2 size-6 text-primary-500" name="package" />
                  </div>

                  <div className="mr-4">
                    <span className="cursor-pointer text-lg font-bold text-gray-700">
                      {prescriptionDetails.name}
                    </span>
                    <div className="mt flex gap-2 text-base">
                      <span className="font-bold">Order</span> {prescriptionDetails.id}
                    </div>
                  </div>
                </div>
              </>
            )}

            {showReleaseFromHoldButton && (
              <Menu
                data-testid="actions_dropdown"
                as="div"
                className="relative inline-block text-left"
              >
                <div>
                  <MenuButton className="inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-[5px] text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue">
                    Actions...
                    <Common.Icon name="arrow-down" className="ml-6" />
                  </MenuButton>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none">
                    <div className="py-1">
                      <MenuItem>
                        {({ active }) => (
                          <a
                            className={getMenuItemClasses(active)}
                            onClick={() => handleReleaseFromHold()}
                          >
                            <Common.Icon name="prescription" />
                            Release from hold
                          </a>
                        )}
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
