import { useEffect, useMemo } from 'react';

import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common';
import { ColorTagProps } from '@thecvlb/design-system/lib/src/common/ColorTag/colorTag.types';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import ReleaseOrderFromHoldAlt from 'components/modals/ReleaseOrderFromHoldAlt';
import { useAppSelector } from 'hooks/redux';
import { useAppDispatch } from 'hooks/redux';
import capitalize from 'lodash/capitalize';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { openModal } from 'store/modal/modalSlice';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import EditButtons from './EditButtons';
import {
  getItems,
  getOrderStatusTags,
  getShowReleaseFromHold,
  getSubmitOrderData,
  orderOptions,
  pharmacyOptions,
  shippingOptions,
  sortOptions
} from './orderInfo.settings';
import { OrderFormValues, OrderInfoProps } from './orderInfo.types';

const OrderInfo: React.FC<OrderInfoProps> = ({
  order,
  headingClassNames,
  labelClassNames,
  isEditable
}) => {
  const { staffMemberOrderTeams } = useAppSelector(({ prescriptionPane }) => prescriptionPane);
  const [isEditing, setIsEditing] = useToggle(false);
  const dispatch = useAppDispatch();
  const [updateOrder, { isLoading: isLoadingEdit }] = useUpdateOrderMutation();

  const { handleSubmit, reset } = useForm<OrderFormValues>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const headingClasses = classNames(headingClassNames || 'mb-2 text-base font-bold text-gray-700');
  const labelClasses = classNames(
    'text-base mr-5 min-w-fit flex-none text-gray',
    labelClassNames || 'w-28'
  );
  const showReleaseFromHoldButton = getShowReleaseFromHold(order, staffMemberOrderTeams);

  const createLegacyStatus = (status: string) => {
    return { label: capitalize(status), value: capitalize(status) };
  };

  const orderStatusOptions = useMemo(() => {
    const includesOrderStatus = order.orderStatus
      ? orderOptions
          .map((item) => item.value.toLocaleLowerCase())
          .includes(order.orderStatus.toLocaleLowerCase())
      : false;
    order.orderStatus &&
      !includesOrderStatus &&
      orderOptions.push(createLegacyStatus(order.orderStatus));
    return orderOptions.sort(sortOptions);
  }, [order]);

  const pharmacyStatusOptions = useMemo(() => {
    const includesPharmacyStatus = order.pharmacyStatus
      ? pharmacyOptions
          .map((item) => item.value.toLocaleLowerCase())
          .includes(order.pharmacyStatus.toLocaleLowerCase())
      : false;
    order.pharmacyStatus &&
      !includesPharmacyStatus &&
      pharmacyOptions.push(createLegacyStatus(order.pharmacyStatus));
    return pharmacyOptions.sort(sortOptions);
  }, [order]);

  const shippingStatusOptions = useMemo(() => {
    const includesShippingStatus = order.shippingStatus
      ? shippingOptions
          .map((item) => item.value.toLocaleLowerCase())
          .includes(order.shippingStatus.toLocaleLowerCase())
      : false;
    order.shippingStatus &&
      !includesShippingStatus &&
      shippingOptions.push(createLegacyStatus(order.shippingStatus));
    return shippingOptions.sort(sortOptions);
  }, [order]);

  useEffect(() => {
    reset({
      ...order,
      shippingStatus: shippingStatusOptions.find(
        (c) => c.value?.toLocaleLowerCase() === order.shippingStatus?.toLocaleLowerCase()
      ),
      orderStatus: orderStatusOptions.find(
        (c) => c.value?.toLocaleLowerCase() === order.orderStatus?.toLocaleLowerCase()
      ),
      pharmacyStatus: pharmacyStatusOptions.find(
        (c) => c.value?.toLocaleLowerCase() === order.pharmacyStatus?.toLocaleLowerCase()
      )
    });
  }, [reset, order, orderStatusOptions, pharmacyStatusOptions, shippingStatusOptions]);

  const onSubmit = async (formData: OrderFormValues) => {
    const submitOrder = getSubmitOrderData(formData);

    updateOrder({ orderId: order.id, order: submitOrder })
      .unwrap()
      .then((data) => {
        notifySuccess(data.message || 'Successfully updated order');
        setIsEditing(false);
      })
      .catch((error) => {
        notifyError(error.data?.message);
      });
  };

  const handleReleaseFromHold = () => {
    const notPaid = order.paymentStatus !== 'paid';
    if (notPaid) {
      dispatch(
        openModal({
          size: 'lg',
          hideClose: true,
          modalContent: <ReleaseOrderFromHoldAlt order={order} />
        })
      );
    } else {
      dispatch(
        openModal({
          size: 'sm',
          hideClose: true,
          modalContent: <ReleaseOrderFromHoldAlt order={order} />
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loader isVisible={isLoadingEdit} />
      <div className="flex justify-between">
        <h4 className={headingClasses}>Prescription detail</h4>
        <div className="flex items-start gap-4">
          {showReleaseFromHoldButton && (
            <Common.Button
              color="white"
              size="sm"
              onClick={handleReleaseFromHold}
              preIcon="prescription"
              type="button"
            >
              Release from hold
            </Common.Button>
          )}
          {isEditable && (
            <EditButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isLoadingEdit={isLoadingEdit}
            />
          )}
        </div>
      </div>
      <div className="mb-6 flex w-60 justify-between">
        {getOrderStatusTags(order).map((el) => {
          return (
            <div key={el.property}>
              <div className="width-1/5 inline-flex flex-row gap-2">
                <Common.Icon className={el.color} name={el.icon as IconProps['name']} />
                <p className={labelClasses}>{el.label}</p>
              </div>
              <Common.ColorTag
                color={el.color?.split('-')[1] as ColorTagProps['color']}
                text={capitalize(order[el.property]) || ('N/A' as string)}
              />
            </div>
          );
        })}
      </div>
      <div className="flex h-[130px] flex-col flex-wrap gap-2">
        {getItems(order).map((el) => {
          return (
            <div key={el.label} className="flex">
              <p className={labelClasses}>{el.label}</p>
              <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>
                {el.value}
              </p>
            </div>
          );
        })}
      </div>
    </form>
  );
};

export default OrderInfo;
