import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { ModalState, OpenModalPayload } from './modalSlice.types';

const initialModalState: ModalState = {
  isOpen: false,
  modalContent: null,
  size: 'base',
  hideClose: false,
  drag: false,
  hideCloseButton: false
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      state.isOpen = true;
      state.hideClose = !!action.payload.hideClose;
      state.modalContent = action.payload.modalContent;
      state.size = action.payload.size || 'base';
      state.drag = !!action.payload.drag;
      state.hideCloseButton = !!action.payload.hideCloseButton;
    },
    changeModalSize: (
      state,
      action: PayloadAction<{
        size: 'sm' | 'base' | 'lg' | 'xl';
      }>,
    ) => {
      state.size = action.payload.size || 'base';
    },
    closeModal: (state, action: PayloadAction<{ funcOnClose?: () => void } | undefined>) => {
      action.payload?.funcOnClose?.();
      state.isOpen = false;
      state.hideClose = false;
      state.modalContent = null;
      state.size = 'base';
      state.drag = false;
      state.hideCloseButton = false;
    }
  }
});

export const selectModal = (state: RootState) => state.modal;

export const { openModal, closeModal, changeModalSize } = modalSlice.actions;

export default modalSlice.reducer;
