import { Common } from '@thecvlb/design-system/lib/src';

const Loading: React.FC = () => {
  return (
    <>
      <div className="align-center mb-4 flex flex-row justify-center gap-2">
        <span className="flex size-16 items-center justify-center rounded-full bg-yellow-100">
          <Common.Icon name="clock" className="size-8 text-yellow" />
        </span>
      </div>
      <h3 className="mb-4 text-center text-xl font-bold text-gray-700">Loading</h3>
      <p className="mb-6 text-center text-base font-medium text-gray-700">
        Attempting to release the order from on hold..
      </p>
    </>
  );
};

export default Loading;
